/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase_config: {
    apiKey: 'AIzaSyD8f1jFGOD79DjS8P1NXuxX4BrEIdF1LXY',
    authDomain: 'avplat-local.firebaseapp.com',
    databaseURL: 'https://avplat-local.firebaseio.com',
    projectId: 'avplat-local',
    storageBucket: 'avplat-local.appspot.com',
    messagingSenderId: '860491845234',
  },
};
export const MapConfig = {
  googleAPIKey: 'AIzaSyAFblizV8dBXm1OlUq6JNVzQKVKKCmlov0'
};
export const APP_CONFIG = {
  MyURL: "",
  URL: "",
  FlpURL: "",
  WebVersion: "3.6.0",
};
export class AppupdateConfig {
  public MinAndroidVersion: string;
  public MinIosVersion: string;
  public MinWebversion: string;
  public url1: {
    FlpUrl: string;
    max: string;
    min: string;
    url: string;
  };
  public url2: {
    FlpUrl: string;
    max: string;
    min: string;
    url: string;
  };
}